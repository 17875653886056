import './index.less';

import Vue from "vue";
import Component from "vue-class-component";
import {
  SaveRefChildGardenDto,
  zsRefChildGardenService,
  GetRefChildGardenPageDto,
  IGarden,
} from "@/app/apiServices/zsRefChildGarden/public-api";
import { getStepsIcon, isNullOrEmpty } from "@/core/utils/index";
import { thisCity_stepsList } from '@/core/utils/enum/thisCity';
import { tbGardenMessage, xbThisCityGardenToHJMessage, xbThisCityGardenToFCMessage } from '@/config/message';
import { GradeLabel } from '@/core/utils/enum/grade';
import storage from '@/core/utils/storage';
import { Table } from '@/app/components/index';

@Component({
  components: { Table }
})
export default class regChildGardenTs extends Vue {
  refChildGarden = new GetRefChildGardenPageDto();
  formModel = new SaveRefChildGardenDto();
  formRule = {
    orderTime: [{ required: true, message: "请选择预约时间", trigger: "change" }],
    gardenIds: [{
      required: true,
      validator: (rule: any, value: Array<number>, callback: any) => {
        if (value.length > 0) {
          callback();
        } else {
          let grade = "";
          const { gradeCode }: any = storage.GET_RegistrationInfo();
          switch (gradeCode) {
            case 'SZNJ_tb':
              grade = "托班登记点";
              break;
            case 'SZNJ_xb':
              grade = "小班报名点";
              break;
            default:
              grade = "插班报名点";
              break;
          }
          callback(new Error(`${grade}不能为空`));
        }
      },
      trigger: 'change'
    }],
  };
  orderTimeList = [];
  stepsList = thisCity_stepsList;
  activeSteps = 3;

  //所有园所
  allGardenList: Array<IGarden> = [];

  //年级
  gradeCode = "";

  //是否显示民办
  isShowMB = -1;

  //是否接受统筹
  isAdjust = false;

  //托班 是否愿意接受在全区范围统筹安排
  tbIsAdjust = -1;

  column = [
    {
      prop: "name",
      label: "园所名称",
      slot: "name",
      align: "center"
    },
    {
      prop: "enrollScope",
      label: "招生范围",
      align: "center"
    },
    {
      prop: "street",
      label: "所属街道",
      align: "center"
    },
    {
      prop: "address",
      label: "园所地址",
      align: "center"
    },
    {
      prop: "telphone",
      label: "电话",
      align: "center"
    }
  ]

  getStepsIcon(index: number) {
    return getStepsIcon(index, this.activeSteps);
  }

  //获取报名园所label
  get gardenLabel() {
    return GradeLabel(this.formModel.gradeCode);
  }

  //是否愿意接受在全区范围统筹安排：
  isShowAdjustTr() {
    return this.refChildGarden.gardenGroupDtos.length == 1 && this.refChildGarden.gardenGroupDtos[0].gardenList[0].ownershipCode == "mb";
  }

  //是否显示“是否报民办幼儿园”
  isShowMBtr(index: number) {
    const mbIndex = this.refChildGarden.gardenGroupDtos.findIndex(u => u.gardenList[0].ownershipCode == "mb");
    if (mbIndex > 0) {
      return index == mbIndex - 1;
    }
    return false;
  }

  //是否显示园所
  isShowGarden(index: number) {
    const gardenList = this.refChildGarden.gardenGroupDtos[index];
    const ownershipCode = gardenList.gardenList[0].ownershipCode;
    if (ownershipCode == 'gb') {
      return true;
    }
    //民办
    else {
      // if (index == 0) {
      //   return true;
      // }
      return this.isShowMB == 1;
    }
  }

  //是否显示接受统筹组件
  isShowCheckBox(item: IGarden) {
    if (this.gradeCode == "SZNJ_tb" && item.ownershipCode == 'gb') {
      const find = this.formModel.gardenIds.find(u => u == item.id);
      return find ? true : false;
    }
    return false;
  }

  //是否显示登记须知
  isShowAdmissionNotes(gardenList: Array<IGarden>) {
    if (this.gradeCode !== 'SZNJ_tb') {
      return false;
    }

    if (gardenList[0].ownershipCode == "gb") {
      let isHave = false;   //是否有登记须知文件
      gardenList.forEach(u => {
        if (!isNullOrEmpty(u.admissionNotes))
          isHave = true;
      });

      return isHave;
    }
    return false;
  }

  //园所change时间
  gardenIdChange(val: Array<number>) {
    //托班本市：只能选择一所公办+一所民办
    if (this.gradeCode == "SZNJ_tb") {

      //公办
      const gb = this.formModel.gardenIds.filter((item: any) => {
        const findGb = this.allGardenList.find(u => u.id == item && u.ownershipCode == "gb");
        if (findGb) {
          return findGb;
        }
      });
      if (gb.length > 1) {
        gb.forEach((item: number, index: number) => {
          //保留最后一条
          if (index !== gb.length - 1) {
            const findIndex = this.formModel.gardenIds.findIndex(u => u == item);
            if (findIndex > -1) {
              this.formModel.gardenIds.splice(findIndex, 1);
            }
          }
        });
        this.isAdjust = false;
      }

      //民办
      const mb = this.formModel.gardenIds.filter((item: any) => {
        const findMb = this.allGardenList.find(u => u.id == item && u.ownershipCode == "mb");
        if (findMb) {
          return findMb;
        }
      });
      if (mb.length > 1) {
        mb.forEach((item: number, index: number) => {
          //保留最后一条
          if (index !== mb.length - 1) {
            const findIndex = this.formModel.gardenIds.findIndex(u => u == item);
            if (findIndex > -1) {
              this.formModel.gardenIds.splice(findIndex, 1);
            }
          }
        });
      }
    }
  }

  tbIsAdjustChange(val: number) {
    this.formModel.isAdjust = val;
  }

  //用于公办园所选择后使用
  isAdjustChange(val: number) {
    this.formModel.isAdjust = val ? 1 : 0;
  }

  isShowMBChange(val: boolean) {
    if (!val) {
      //清除民办幼儿园所
      this.formModel.gardenIds.forEach(item => {
        const findMb = this.allGardenList.find(u => u.id == item && u.ownershipCode == "mb");
        if (findMb) {
          //重新获取当前下标
          const findIndex = this.formModel.gardenIds.findIndex(u => u == item);
          if (findIndex > -1) {
            this.formModel.gardenIds.splice(findIndex, 1);
          }
        }
      })
    }
  }

  //查看pdf
  viewPDF(url: string) {
    window.open(url)
  }

  //检查园所(只用于小班、托班)
  checkGarden() {
    //小班、托班非本市只能选择一所公办+一所民办
    if (this.isShowAdjustTr() && !this.isAdjust) {
      this.$toast(`请选择是否愿意接受在全区范围统筹安排`);
      return;
    }

    if (this.isShowMB == -1) {
      this.$toast(`请选择是否报民办幼儿园`);
      return;
    }

    if (!this.isAdjust && this.formModel.gardenIds.length == 0) {
      this.$toast(`${this.gardenLabel}不能为空`);
      return false;
    }

    //托班本市：只能选择一所公办+一所民办
    if (this.gradeCode == "SZNJ_tb") {
      //公办
      const gb = this.formModel.gardenIds.filter((item: any) => {
        const findGb = this.allGardenList.find(u => u.id == item && u.ownershipCode == "gb");
        if (findGb) {
          return findGb;
        }
      });

      //民办
      const mb = this.formModel.gardenIds.filter((item: any) => {
        const findMb = this.allGardenList.find(u => u.id == item && u.ownershipCode == "mb");
        if (findMb) {
          return findMb;
        }
      });

      if (gb.length > 1 || mb.length > 1) {
        this.$toast(tbGardenMessage);
        return false;
      }
    }
    //小班本市：同一个示范园只能选择一个园部
    else if (this.gradeCode == "SZNJ_xb") {

      let isHaveSF = false;   //是否选择示范园
      let isHaveGB = false;   //是否选择公办园
      let message = "";   //提示信息

      this.formModel.gardenIds.forEach((item: any) => {
        //查找当前园所
        const garden = this.allGardenList.find(u => u.id == item);
        if (garden) {
          //示范园
          if (garden.level == 0) {
            isHaveSF = true;

            //查找父级园所
            const findParent = this.allGardenList.find(u => u.id == garden.parentID);
            if (findParent) {
              const findGarden = this.formModel.gardenIds.find(u => u == findParent.id);
              message = findGarden ? `${findParent.name}和${garden.name}只能二者选一，请家长根据实际情况就近报名` : "";
              return false;
            }
          }
          //公办园
          if (garden.level > 0 && garden.ownershipCode == "gb") {
            isHaveGB = true;
          }
        }
      })
      if (!isNullOrEmpty(message)) {
        this.$dialog.alert({
          title: '提示',
          message: message,
        }).then(() => {
          // on close
        });
        return false;
      }

      if (isHaveSF && !isHaveGB) {
        const { enterGardenType }: any = storage.GET_RegistrationInfo();
        this.$dialog.alert({
          title: '提示',
          message: enterGardenType == 1 ? xbThisCityGardenToHJMessage : xbThisCityGardenToFCMessage,
        }).then(() => {
          // on close
        });
        return false;
      }

    }
    return true;
  }

  async viewLoad() {
    const { childId, enterGardenType, gradeCode }: any = storage.GET_RegistrationInfo();
    this.refChildGarden = await zsRefChildGardenService.getRefChildGardenPage({
      childId: childId,
      enterGardenType: enterGardenType,
      gradeCode: gradeCode,
    });

    this.gradeCode = gradeCode;
    this.orderTimeList = this.refChildGarden.orderTimeList || [];

    //初始化提交值
    this.formModel.childId = childId;
    this.formModel.enterGardenType = enterGardenType;
    this.formModel.gradeCode = gradeCode;

    //展平所有园所信息
    this.refChildGarden.gardenGroupDtos.forEach((group: any) => {
      group.gardenList.forEach((item: any) => {
        this.allGardenList.push(item);
      });
    });
  }

  nextOnClick() {
    if (this.gradeCode == 'SZNJ_tb' || this.gradeCode == 'SZNJ_xb') {

      if (!this.checkGarden()) {
        return;
      }
      this.toNextPage();
    }
    else {
      const { formModel }: any = this.$refs;
      formModel.validate((valid: boolean) => {
        if (valid) {
          this.toNextPage();
        }
      });
    }
  }

  toNextPage() {
    this.$store.dispatch('ACTION_RegChildGarden', this.formModel);
    this.$router.push(`/thisCity/confirmInformation`);
  }

  mounted(): void {
    this.viewLoad();
  }
}